import React, { useEffect } from 'react';

// Packages

import { useNavigate } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

// Media

import Logo from './Logo.png';

/*



  START PAGE



*/

export default function Index() {

  const navigate = useNavigate();

  const Pulsate = keyframes`
    0% {
      transform: scale(0.88);
    }
    70% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(0.98);
    }
  `;

  const StyledImage = styled(Image)`
    animation: ${Pulsate} 1s ease-in-out infinite;
  `;

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/story/create');
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 d-flex" style={{ backgroundColor :  "#014677" }}>
      <Container style={{ backgroundColor : "#014677", width: "100vw", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <StyledImage src={Logo} style={{ 'width' : 320 }} />
      </Container>
    </div>
  );
}
