import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './StorySlider.css';

const StorySlider = () => {
  
  const { id } = useParams();

  const [story, setStory] = useState({ story: [] });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const fetchStory = async () => {
      const response = await fetch(`https://story.azure-api.net/story/id/${id}`)
      const data = await response.json();
      console.log(data.data)
      setStory(data.data);
    };
    fetchStory();
  }, []);

  const handleShowText = () => {
    setShowText(!showText);
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < story.story.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="story-slider">
      {story.story.length > 0 && (
        <div className="story-slider__container">
          <div className="story-slider__image-container">
            <img
              src={story.story[currentIndex].url}
              alt={story.title}
              className="story-slider__image"
            />
            {showText && (
              <div className="story-slider__text">
                <div className="story-slider__text-content">
                  <b>{story.story[currentIndex].text}</b>
                </div>
              </div>
            )}
          </div>
          <div className="story-slider__nav">
            <button
              className="story-slider__nav-btn"
              onClick={handlePrev}
            >
              Prev
            </button>
            <button
              className="story-slider__button"
              onClick={handleShowText}
            >
              {showText? 'Hide Text' : 'Show Text'}
            </button>
            <button
              className="story-slider__nav-btn"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StorySlider;