import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import Header from '../../components/Header'

import { Container, Row, Col, Button, Form, FormGroup } from'react-bootstrap';

import './Create.css';

function CreateStory() {
  const [step, setStep] = useState(1);

  const [title, setTitle] = useState('The Adventures of Benny the Bee');
  const [description, setDescription] = useState('A happy bee flying in a sunny meadow');
  const [imagePrompt, setImagePrompt] = useState('');
  const [text, setText] = useState('Once upon a time, in a sunny meadow, there was a little bee named Benny. Benny loved to fly from flower to flower, collecting nectar to make honey for his hive. One day, while flying, Benny met a friendly butterfly named Bella. Bella was lost and couldnt find her way back to her garden. Benny offered to help Bella find her way home');
  const [boundaries, setBoundaries] = useState('Age 4-8, Reading Level: Beginner, Genre: Childrens story');
  const [themes, setThemes] = useState('Friendship, Helping Others, Exploration');
  const [context, setContext] = useState('A sunny meadow with flowers and trees, Benny the Bee and Bella the Butterfly');
  const [learningOutcome, setLearningOutcome] = useState('Children will learn about the importance of helping others and making new friends, Children will develop their problem-solving skills and learn about navigation and directions, Children will be exposed to basic story structure and vocabulary');

  const [loading, setLoading] = useState(false);

  /*

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imagePrompt, setImagePrompt] = useState('');
  const [text, setText] = useState('');
  const [boundaries, setBoundaries] = useState('');
  const [themes, setThemes] = useState('');
  const [context, setContext] = useState('');
  const [learningOutcome, setLearningOutcome] = useState('');

  */

  const navigate = useNavigate();

  const handleSubmit = (event) => {

    setLoading(true);
    event.preventDefault();
    
    /*
    const story = {
      title: "The Adventures of Benny the Bee",
      imagePrompt: "A happy bee flying in a sunny meadow",
      text: "Once upon a time, in a sunny meadow, there was a little bee named Benny. Benny loved to fly from flower to flower, collecting nectar to make honey for his hive. One day, while flying, Benny met a friendly butterfly named Bella. Bella was lost and couldn't find her way back to her garden. Benny offered to help Bella find her way home.",
      boundaries: "Age 4-8, Reading Level: Beginner, Genre: Children's story",
      themes: "Friendship, Helping Others, Exploration",
      context: "A sunny meadow with flowers and trees, Benny the Bee and Bella the Butterfly",
      learningOutcome: "Children will learn about the importance of helping others and making new friends, Children will develop their problem-solving skills and learn about navigation and directions, Children will be exposed to basic story structure and vocabulary"
    };*/

    const story = {
      title: title,
      imagePrompt: imagePrompt,
      text: text,
      boundaries: boundaries,
      themes: themes,
      context: context,
      learningOutcome: learningOutcome
    };


    fetch('https://story.azure-api.net/story', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(story),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      
       if (data.success) {
         navigate(`/story/id/${data.data.id}`); // Note the change to data.data._id
       } else {
        setLoading(false);
         console.log('Failed to create story.');
       }
     })
    .catch((error) => setLoading(false));
  
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  return (
    <>

    <Container fluid className="create-story-container py-5" style={{ backgroundColor : '#014677', minHeight : "100vh" }}>
      <Header />
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="px-5">
          {step === 1 && (
            <div className="intro-step text-center">
              <h1 className="font-weight-bold font-helvetica mb-3">Create Your Story</h1>
              <p className="font-open-sans text-muted mb-5">Start by telling us about your story</p>
              <div style={{ height : 150 }}></div>
              <div style={{ textAlign : "right" }}>

                <Button variant="primary" size="lg" onClick={handleNext} style={{ width : 150, height : 40, fontSize : 18 }} className="mb-3">
                  Next
                </Button>

              </div>
              
            </div>
          )}
          {step === 2 && (
            <div className="story-step">
              <h2 className="font-weight-bold font-helvetica mb-3">Write Your Story</h2>
              <FormGroup className="mb-4" style={{ marginBottom : 15 }}>
                <div><label className="font-open-sans">TITLE</label></div>
                <div>
                  <input
                    type="text"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    className="font-open-sans"
                    style={{ borderRadius : 5, width : "100%" }}
                  />
                </div>
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <label className="font-open-sans">DESCRIPTION</label><br></br>
                <textarea
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <label className="font-open-sans">IMAGES DESCRIPTION</label><br></br>
                <input
                  type="text"
                  value={imagePrompt}
                  onChange={(event) => setImagePrompt(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <label className="font-open-sans">STORY</label><br></br>
                <textarea
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <div className="d-flex justify-content-between">
                <Button variant="secondary" size="lg" onClick={handlePrevious} style={{ marginRight : 15 }} className="mr-3">
                  Previous
                </Button>
                <Button variant="primary" size="lg" onClick={handleNext}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="options-step">
              <h2 className="font-weight-bold font-helvetica mb-3">DEFINE STORY</h2>
              <FormGroup className="mb-4" style={{ marginBottom : 15 }}>
                <div><label className="font-open-sans" style={{ marginBottom : 5 }}>BOUNDARIES</label></div>
                <div>

                  <textarea
                    value={boundaries}
                    onChange={(event) => setBoundaries(event.target.value)}
                    className="font-open-sans"
                    style={{ borderRadius : 5, width : "100%" }}
                  />

                </div>
                
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <div><label className="font-open-sans">THEMES</label></div>
                <textarea
                  value={themes}
                  onChange={(event) => setThemes(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <div><label className="font-open-sans">CONTEXT</label></div>
                <textarea
                  value={context}
                  onChange={(event) => setContext(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <FormGroup className="mb-4"  style={{ marginBottom : 15 }}>
                <div><label className="font-open-sans">LEARNING OUTCOME</label></div>
                <textarea
                  value={learningOutcome}
                  onChange={(event) => setLearningOutcome(event.target.value)}
                  className="font-open-sans"
                  style={{ borderRadius : 5, width : "100%" }}
                />
              </FormGroup>
              <div className="d-flex justify-content-between">
                <Button variant="secondary" size="lg" onClick={handlePrevious}  style={{ marginRight : 15 }} className="mr-3">
                  Previous
                </Button>
                <Button variant="primary" size="lg" onClick={handleNext}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="review-step text-center">
              <h2 className="font-weight-bold font-helvetica mb-3">Create Your Story</h2>
              <p className="font-open-sans text-muted mb-5">Please review your story before submitting</p>
              <div style={{ height: 150 }}></div>
              <Button variant="primary" size="lg" onClick={handleSubmit} style={{ marginRight: 15 }} className="mb-3">
                {loading? (
                  <span>
                    LOADING...
                    
                  </span>
                ) : (
                  "Generate"
                )}
              </Button>
              <Button variant="secondary" size="lg" onClick={handlePrevious} className="mb-3">
                Previous
              </Button>
              {loading && (
                <p style={{ color: "white" }}>LOADING...</p>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
    </>
  );

}

export default CreateStory;