import React from 'react';
import logo from './Logo.png'; // Adjust the path based on where you moved the logo

function Header() {
  return (
    <div style={{
      height: '80px', // Sets the height of the header
      padding: '5px', // Adds padding inside the header
      display: 'flex', // Enables flexbox for easy centering
      justifyContent: 'center', // Centers the content horizontally
      alignItems: 'center', // Centers the content vertically
      backgroundColor: '#014677', // Optional: Adds a light gray background
    }}>
      <img src={logo} alt="Logo" style={{
        maxHeight: '100%', // Ensures the image doesn't exceed the header's height
        maxWidth: '100%', // Ensures the image doesn't exceed the header's width
      }} />
    </div>
  );
}

export default Header;