import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Index/Index';
import CreateStory from './pages/Story/Create/Create';
import StoryById from './pages/Story/Id/components/StorySlider';

import './App.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Index />} />
     <Route path="/story/create" element={<CreateStory />} />
        <Route path="/story/id/:id" element={<StoryById />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;